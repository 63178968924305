@font-face {
  font-family: "BrandonThinIT";
  src: local("BrandonThinIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_thin_it.otf")
      format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonThin";
  src: local("BrandonThin"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_thin.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "BrandonRegularIT";
  src: local("BrandonRegularIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_reg_it.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonRegular";
  src: local("BrandonRegular"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_reg.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "BrandonMediumIT";
  src: local("BrandonMediumIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_med_it.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonGMedium";
  src: local("BrandonGMedium"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_med.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "BrandonMedium";
  src: local("BrandonMedium"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_med.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "BrandonLightIT";
  src: local("BrandonLightIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_light_it.otf")
      format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonLight";
  src: local("BrandonLight"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_light.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "BrandonBlackIT";
  src: local("BrandonBlackIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_blk_it.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonBlack";
  src: local("BrandonBlack"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_blk.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "BrandonBoldIT";
  src: local("BrandonBoldIT"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_bld_it.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "BrandonBold";
  src: local("BrandonBold"),
    url("./assets/Fonts/BrandonGrotesque/Brandon_bld.otf") format("opentype");
  font-display: swap;
}
/* Custom font style classes */

.brandomtxt-medium {
  font-family: BrandonMedium, sans-serif !important;
}
.brandomtxt-regular {
  font-family: BrandonRegular, sans-serif !important;
}
.brandomG-medium {
  font-family: BrandonGMedium, sans-serif !important;
}
.brandomtxt-light {
  font-family: BrandonLight, sans-serif !important;
}
.brandombold {
  font-family: BrandonBold, sans-serif !important;
}

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: BrandonRegular, sans-serif !important;
  background-color: #f7f7f7;
}
.mvh-100 {
  min-height: 100vh;
}
h4 {
  font-family: BrandonRegular, sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon1 {
  margin-left: 232px;
  margin-top: -40px;
  padding-bottom: 5px;
}
.icon {
  margin-left: 2px;
  margin-top: -4px;
}
.link {
  margin-left: 2rem;
}

/* Header css */
.header {
  /* border-bottom:1px solid rgb(28, 27, 27); */
  padding: 1rem 0;
  margin-bottom: 3rem;
  box-shadow: 3px 3px 16px 3px grey;
  border: unset;
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #fff;
}
.header-logo {
  width: 34px;
  height: 34px;
  /* padding: 5px; */
  border-radius: 8px;
  /* background-color: rgb(206, 233, 239); */
}

.content-spacing-top {
  padding-top: 5rem;
}

.offer-frame {
  width: 170px;
  height: 170px;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  padding: 1.25rem;
  background-color: #8fa2aa;
}
.line {
  font-size: 14px;
  margin-left: 18px;
}
.underline {
  border-bottom: 0.4px solid #6a6969;
  padding-bottom: 14px;
}
.mycard {
  font-size: 33rem;
}

.signup-formcontrol {
  margin-bottom: 10px !important;
}

legend {
  display: none !important;
}

/* Footer menu css */
.fmenu-b > img {
  width: 100%;
}
.fmenu-b {
  background-color: #a7a9ac;
  padding: 6px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
}

.link-blk {
  color: #a7a9ac !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.active-blu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.active-blu > .fmenu-b {
  background-color: #4b4c4e;
}

.pay-out-btn {
  position: fixed !important;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%);
  background-color: #fff !important;
}
.map {
  color: #4b4c4e !important;
  box-shadow: 0 0 8px 0px #e5e5e5 !important;
  border: 1px solid #9d9b9b !important;
  font-size: 17px !important;
}

.bottom-menu > div {
  text-align: center;
}

/* Cards Css */
.reward-card {
  border: 1px solid rgb(28, 27, 27);
  border-radius: 1.4rem;
}
.card-list-block {
  max-height: 43vh;
  overflow: auto;
}
.cModal {
  width: 300px !important;
}

.bottom-center {
  bottom: 20%;
  left: 35%;
  padding: 4px;
}
.left-top-ali {
  top: 6%;
  left: 5%;
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 50%;
}
.right-top-ali {
  top: 5%;
  right: 3%;
}

.right-bottom-ali {
  bottom: 3%;
  right: 2%;
}

.card-action > div {
  background-color: #ffffff;
  border-radius: 50%;
}

.hot-details {
  top: 0;
  width: 100%;
  color: #fff;
}
.hotel-name-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.offer-brand {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.offer-info > p {
  float: left;
  margin-right: 5px;
}

.offer-cusine {
  max-width: 32vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.branch-center-name {
  width: 90vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin: auto !important;
}

.fixed-mid-bottom {
  position: fixed;
  bottom: 45px;
  left: 0;
  width: 100vw;
  text-align: center;
  z-index: 1000;
}

@media all and (max-width: 767px) {
  .fixed-mid-bottom {
    bottom: 75px;
  }
}

.MuiToggleButton-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.MuiToggleButtonGroup-root .Mui-selected {
  color: #fff !important;

  background-color: blue !important;
}

.trans-history {
  background: #fff;
  box-shadow: 0px -10px 12px -12px rgb(28 27 27);
}
.list-help p {
  color: #777777;
}
.list-help > a {
  list-style: none;
  padding: 12px;
  background-color: #f7f7f7;
  margin-bottom: 14px;
  color: #000;
  display: block;
}
.support-attch {
  border: 1px solid #e0e0e0;
  padding: 5.5px 14px;
  border-radius: 4px;
  color: #c3c0c0;
  height: 40px;
}

.fixed-support-header {
  box-shadow: 3px 3px 12px 1px grey;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}
.header-search-icon {
  width: 25px;
}
.contect-box {
  padding-top: 2rem;
}

/* Side Bar css */
.sidebar-logo > img {
  width: 100%;
}
.sidebar-logo {
  width: 50px;
  height: 50px;
  /* background-color: rgb(206, 233, 239); */
  border-radius: 0.6rem;
  padding: 0.6rem;
  margin-left: 1rem;
}
.list-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.profile-icon {
  background: #8fa2aa;
  border-radius: 50%;
  padding: 5px;
}

/* Google login button css */
.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: rgb(167, 23, 23);
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  border-radius: 22rem;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto", sans-serif;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669f2;
}

.login_with > span {
  color: #fff;
  background-color: #4b4c4e;
  padding: 10px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.login_with {
  margin: 0 auto;
  border-radius: 5px !important;
  box-shadow: unset !important;
}

/* Home screen Categry css */
.cat-img {
  width: 10%;
}

/* Search Page css */
.category-box {
  width: 160px;
  height: 160px;
  background-color: #8fa2aa;
  border-radius: 1rem;
  position: relative;
}
.catagory-img {
  width: 28%;
  margin: auto;
}
.cat-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
}
.drop-down {
  background: #fff; /* #8fa1ab; */
  border-radius: 1rem;
  position: absolute;
  width: 96%;
  z-index: 9;
  box-shadow: 0 0 9px 1px #8fa1ab; /* #f7f7f7 */
  margin-top: 0.5rem;
  margin-left: 2%;
  min-height: 74px;
  max-height: 210px;
  overflow: auto;
}

/* Savings page css */
.summary {
  flex: 0 0 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 20px #00000040;
  padding: 0.615rem;
}
.save-list-img {
  width: 35px;
}
.nsm7Bb-HzV7m-LgbsSe.MFS4be-JaPV2b-Ia7Qfc {
  background-color: #202124;
  color: #ffffff;
}

/* toastify css */
.toastify {
  max-width: calc(80% - 20px) !important;
}

/* Category slider */
.cat-slider .swiper-slide {
  width: auto !important;
}

.cat-chip {
  padding: 1px 10px;
  border-radius: 20px;
  border: 1px solid rgb(185 185 185);
  background-color: rgb(211 211 211 / 76%);
  color: rgb(19, 19, 19);
  width: fit-content;
  cursor: pointer;
}
.cat-chip.active {
  background-color: #4b4c4e;
  color: #fff;
}

.sudo-line {
  position: relative;
  padding-bottom: 13px;
}
.sudo-line::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #4b4c4e;
}
.sudo-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
img.offer-card-image_small {
  border-radius: 15px;
  width: 100%;
  height: 14vw;
  filter: brightness(0.75);
  -webkit-filter: brightness(0.75);
}
img.offer-card-image {
  border-radius: 15px;
  width: 100%;
  height: 18vw;
  filter: brightness(0.75);
  -webkit-filter: brightness(0.75);
}
img.offer-card-image::before {
  background-color: hsl(0, 0%, 93.3%);
  display: block;
  height: 100%;
  border-radius: 4px;
  content: " ";
  position: absolute;
  width: 100%;
}

img.offer-card-image::after {
  content: attr(alt);
  font-weight: bold;
  position: absolute;
  height: 100%;
  left: 50%;
  text-align: center;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -10%);
  text-transform: capitalize;
}

/**
 * Firefox browser shows the image alt text alongside
 * the alt text generated by the content property,
 * but directly targeting the alt attribute
 * works.
 */
img[alt] {
  text-align: center;
  font-weight: bold;
  color: hsl(0, 0%, 60%);
}
.locate-me {
  z-index: 9;
  background: #fff;
  padding: 6px 8px;
  left: 1%;
  top: 2%;
  box-shadow: 0 0 1px;
  position: absolute;
}
.text-shadows {
  text-shadow: 1px 1px #000 !important;
  -webkit-text-shadow: 1px 1px #000 !important;
}
/* Media screens css */
@media screen and (max-width: 600px) {
  img.offer-card-image {
    height: 54vw;
  }
  .near-by-box-3 {
    width: 29vw;
  }
  .near-by-box-2 {
    width: 43vw;
  }
  .price-range,
  .offer-cusine,
  .offer-stars {
    font-size: 3.2vw !important;
    font-family: BrandonRegular, sans-serif !important;
  }
  .summary {
    flex: 0 0 36vw;
    height: 34vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 20px #00000040;
    padding: 0.615rem;
  }
  .cModal {
    width: 90% !important;
  }
  .summary-title {
    font-size: 4.5vw !important;
  }
  .summary-title-2 {
    font-size: 3.3vw !important;
  }
  .category-box {
    width: 41vw;
    height: 41vw;
    background-color: #8fa2aa;
    border-radius: 1rem;
    position: relative;
  }
  .catagory-img {
    width: 60%;
    margin: auto;
  }
  .footer-logo {
    width: 14%;
    margin-bottom: unset;
  }

  /* .footer-menu{
    z-index: 9999999999; background-color: #fff;
  } */
  .bg-blue {
    height: 100vh;
    overflow: auto;
    padding-bottom: 100px;
  }
  .fav-img {
    width: 100%;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    filter: brightness(0.75);
    -webkit-filter: brightness(0.75);
  }
  .text-shadows {
    text-shadow: 1px 1px #000 !important;
    -webkit-text-shadow: 1px 1px #000 !important;
  }
  .fav-title {
    flex: 1 1 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .letter {
    margin: 0;
    font-family: brandomtxt-regular, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    color: #4b4c4e;
    /* 03366452000 */

    text-align: unset;
  }
  .address {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5;
    color: #58595b;

    padding: 10px;
  }
  .reward-card {
    width: 100%;
  }
  .mw-100 {
    width: 100%;
  }
  .small-card {
    font-size: 13px !important;
    text-align: center;
  }
  span.small-card {
    font-size: 7px !important;
    text-align: center;
    display: block;
  }
  .lt-0 {
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 290px) {
  .auth-stack {
    flex-wrap: wrap;
    justify-content: center !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #2196f3;
  padding: 10px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 200px 0;
  font-size: 30px;
}

/* Signup css */
.form-err-txt {
  font-size: 13px !important;
  color: red !important;
  margin-top: 10px !important;
}

.logo-frame {
  width: 125px;
  height: 125px;
  margin: auto;
  box-shadow: 0 0 16px 5px rgb(205 205 205 / 68%);
  border-radius: 20px;
  text-align: center;
  padding: 1.25rem;
}
.logo-frame1 {
  width: 42px;
  height: 39px;
  margin: auto;
  box-shadow: 0 0 16px 5px rgb(205 205 205 / 32%);
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  margin-left: 80px;
  background-color: #a4cde1e6;
  padding-top: 6px;
  margin-top: 3px;
  margin-bottom: 7px;
}
.back {
  background-color: rgb(206, 233, 239);
  margin: -1rem;
  padding: 11rem 5rem 5rem 5rem;
  margin-top: -3rem;
}
.line {
  font-size: 14px;
  margin-left: 18px;
}
.underline {
  border-bottom: 0.4px solid black;
  padding-bottom: 14px;
}
.mycard {
  font-size: 33rem;
}
.mb-1 {
  border-radius: 23rem;
}
.m3 {
  background-color: white;
  border-radius: 20rem;
}

.logo-frame2 {
  width: 150px;
  height: 150px;
  margin: auto;
  box-shadow: 0 0 16px 5px rgb(205 205 205 / 68%);
  border-radius: 20px;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  background-color: rgb(206, 233, 239);
}
.label1 {
  font-size: 13px;
}

.hs {
  margin-top: 2rem;
}
.gapp {
  margin-top: 2rem;
}
.button1 {
  background-color: #2196f3;
}
.image {
  margin-left: 12rem;
  margin-top: -28px;
  width: 95px;
}

.search {
  box-shadow: 52px -54px 56px 57px grey;
  margin-bottom: 0rem;
}

.tc {
  margin-top: -9px;
}
.text-line {
  background-color: transparent;
  color: #58595b;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: groove 3px;
  padding: 2px 2px;
  font-weight: 400;
}
.text-line1 {
  background-color: transparent;
  color: #58595b;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: groove 3px;
  padding: 2px 2px;
  font-weight: 400;
  margin-top: -19px;
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: darkgray;
  color: white;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 4rem;
  margin-bottom: 11px;
}

.block:hover {
  background-color: #ddd;
  color: black;
}
.pb-4 {
  margin-top: 18px;
}
.below {
  margin-left: 81px;
  font-size: 13px;
}
.hk {
  background-color: #2196f3;
}

.label {
  padding-bottom: 25px;
  border-bottom-style: groove;
  border-bottom-width: 3.1px;
  width: fit-content;
  margin: 10px;
}
.create {
  background-color: white;
  width: 19rem;
  margin-left: -1rem;
}
* {
  margin: 0;
  padding: 0;
}
.color {
  background-color: rgb(206, 233, 239);
  margin: -1rem;
}
.signup-formcontrol {
  margin-bottom: 10px !important;
}

legend {
  display: none !important;
}

.reward-card {
  border: 1px solid rgb(28, 27, 27);
  border-radius: 1.4rem;
}
.header {
  /* border-bottom:1px solid rgb(28, 27, 27); */
  padding: 0.21rem 0;
  box-shadow: 0px 0px 10px 0px rgb(28 27 27);
  border: unset;
}
.bottom-menu {
  padding: 5px 12px 3px 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0px 6px 0px rgb(28 27 27);
  z-index: 999;
  background-color: #f7f7f7;
}
.bottom-menu-divider {
  height: 35px !important;
  border-color: rgb(0 204 249) !important;
}
.bottom-menu > div {
  text-align: center;
}
.japanese {
  font-size: 10px;
}

.center-bottom-ali {
  left: 48%;
  transform: translate(-50%, -50%);
  top: 93%;
}

.right-bottom-ali {
  bottom: 3%;
  right: 2%;
}
.left-bottom-ali {
  bottom: 4%;
  left: 3%;
  background: #8fa2aa;
  padding: 2px 20px;
  border-radius: 15px;
}

.hot-details {
  top: 0;
  width: 100%;
  color: #fff;
}
.hotel-name-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.active-blu {
  color: #4b4c4e;
}
.MuiToggleButton-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.MuiToggleButtonGroup-root .Mui-selected {
  color: #fff !important;

  background-color: blue !important;
}

.list-help p {
  color: #777777;
}
.list-help > a {
  list-style: none;
  padding: 12px;
  background-color: #f7f7f7;
  margin-bottom: 14px;
  color: #000;
  display: block;
}
.support-attch {
  border: 1px solid #e0e0e0;
  padding: 5.5px 14px;
  border-radius: 4px;
  color: #c3c0c0;
  height: 40px;
}
.footer-logo {
  width: 40px;
  margin: auto;
  margin-bottom: 40px;
}
.center-offer {
  font-size: 1.5rem !important;
}

@media screen and (max-width: 425px) {
  .center-offer {
    font-size: 5vw !important;
  }
  .trend-fav2 {
    font-size: 3.5vw !important;
  }
  .footer-logo {
    width: 11%;
  }
  .bg-blue {
    height: 100vh;
    overflow: auto;
    padding-bottom: 100px;
  }

  .reward-card {
    width: 100%;
  }
  .mw-100 {
    width: 100%;
  }
  .small-card {
    font-size: 13px !important;
    text-align: center;
    font-weight: 600;
  }
  span.small-card {
    font-size: 3vw !important;
    text-align: center;
    display: block;
  }
  .location-card-dis {
    font-size: 10px !important;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #2196f3;
  padding: 10px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 200px 0;
  font-size: 30px;
}
.smll {
  margin-top: -9px;
}
/* Hemant code starts from here */
.circle {
  width: 119px;
  height: 115px;
  line-height: 107px;
  border-radius: 50%;
  font-size: 19px;
  color: #141212c4;
  text-align: center;
  background: white;
  box-shadow: 3px 7px 11px #321108a8;
}
.middle {
  /*margin-left: 43px; */
  margin-top: -58px;
  padding: 36px;
}
p.normal {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}
.vl {
  border-left: 6px solid green;
  height: 500px;
}
.sav {
  margin-top: 2rem;
}
.newspaper {
  column-count: 2;
  column-gap: 87px;
  column-rule: 1px double #7c727c;
  margin-top: -27px;
}
.chart1 {
  width: 91%;
  margin-left: 13px;
  margin-top: 6px;
}
.icon2 {
  background-color: #2196f3;
}
.you {
  margin-bottom: 20px;
  margin-left: -2px;
}
.you1 {
  margin-left: -2px;
  margin-top: -33px;
}
.watch {
  color: #2196f3;
}
.link-blk {
  color: #a7a9ac !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.que {
  margin-top: -3rem;
}
.qq {
  margin-left: 39px;
  font-size: 19px;
  font-weight: 300;
}
.cr {
  margin-top: 83px;
}
.qq1 {
  margin-left: 39px;
  font-size: 19px;
  font-weight: 300;
  padding-top: 2px;
  margin-top: -10px;
}
.hh1 {
  margin-top: 2rem;
}
.push {
  margin-top: -53px;
  padding-bottom: 28px;
  font-weight: 400;
  color: #161414;
  font-size: 9px;
}
.iconss {
  margin-top: 0px;
  width: 60px;
  padding-left: 23px;
  margin-bottom: -29px;
  margin-left: -24px;
}
.iconss1 {
  /* margin-top: 0px; */
  width: 31px;
  /* padding-left: 10px; */
  /* margin-bottom: -13px; */
  margin-left: 1px;
  /* margin-top: -10px; */
  margin-top: -23px;
}
.example {
  background: blue;
}
.example:hover {
  background: rgb(158, 153, 153);
}
.block {
  display: block;
  width: 27rem;
  border: none;
  background-color: #dae9e349;
  padding: 14px 28px;
  font-size: 1.7rem;
  margin-right: 2rem;
  font-weight: 900;
}

.block1 {
  display: block;
  border: none;
  background-color: #05301f49;
  padding: 14px 28px;
  font-size: 73%;
  margin: 1.3rem;
  border-radius: 2rem;
  font-weight: 200;
}
.block2 {
  display: block;
  width: 326px;
  border: none;
  background-color: white;
  padding: 14px 28px;
  margin: 1.3rem;
  border-radius: 2rem;
  font-weight: 200;
  box-shadow: 0px 0px 2px;
  margin-left: 1px;
}
.contact {
  display: block;
  border: none;
  background-color: black;
  padding: 14px 28px;
  font-size: 73%;
  margin: 2rem;
  margin-left: 3rem;
  width: 59%;
  color: white;
}
.block3 {
  display: block;
  width: 100%;
  /* border: none; */
  background-color: white;
  padding: 14px 28px;
  margin: 1.3rem;
  border-radius: 1.2rem;
  font-weight: 200;
  box-shadow: 0px 0px 2px;
  height: 25rem;
  padding-inline-start: 1rem;
  margin-left: 1px;
  border: 0.2px solid #0000004a;
}
.block31 {
  display: block;
  width: 100%;
  /* border: none; */
  background-color: white;
  padding: 14px 28px;
  /* margin: 1.3rem; */
  border-radius: 1.2rem;
  font-weight: 200;
  box-shadow: 0px 0px 2px;
  height: 52px;
  padding-inline-start: 1rem;
  /* margin-left: 1px; */
  margin-top: 97px;
  border: 0.2px solid #0000004a;
}
.block311 {
  display: block;
  width: 100%;
  /* border: none; */
  background-color: white;
  padding: 14px 28px;
  /* margin: 1.3rem; */
  border-radius: 1.2rem;
  font-weight: 200;
  box-shadow: 0px 0px 2px;
  height: 52px;
  padding-inline-start: 1rem;
  /* margin-left: 1px; */
  margin-top: 97px;
  border: 0.2px solid #00000078;
}
.ggl {
  margin-left: 6rem;
  margin-top: -48px;
  margin-bottom: -85px;
  padding-bottom: 64px;
}
.belo {
  font-size: small;
  font-weight: 300;
  margin-top: -11px;
  margin-bottom: 14px;
  text-align: center;
}

.pq {
  border-radius: 22px;
}

.ish {
  margin-top: 14px;
  margin-bottom: 24px;
}
.belo1 {
  font-size: small;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 13px;
  text-align: center;
}
.gm-ui-hover-effect {
  display: none !important;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.MuiTablePagination-displayedRows {
  display: none;
}
.MuiTablePagination-selectLabel {
  margin-bottom: 5px !important;
}

@media screen and (min-width: 894px) and (max-width: 1200px) {
  .home_modal {
    width: 45% !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 894px) {
  img.offer-card-image,
  img.offer-card-image_small {
    height: 24.5vw;
  }
  .home_modal {
    width: 64% !important;
  }
}
@media screen and (min-width: 894px) {
  .bottom-menu {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .home_modal {
    width: 30% !important;
  }
}
@media screen and (min-width: 600px) {
  .cat-img > img {
    width: 36px;
  }
  .auth-box {
    width: 500px;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0;
  }
  .card-grid {
    width: 100%;
  }
  .fav-img {
    height: "auto";
    width: "100%";
  }
  img.offer-card-image_small {
    height: 32.5vw;
  }
  .row_per_page {
    max-width: 80px;
    flex: 0 0 40px;
  }
  .cat-img {
    padding: 8px;
    border-radius: 10px;
    width: 12vw;
    height: 12vw;
    display: flex;
  }
  .cat-img > img {
    width: 100%;
  }
}
.fav-img {
  width: 100%;
  height: 100%;
  border-radius: 0.71rem;
  box-shadow: 0px 3px 8px 1px #000000b5;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 2rem !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 2rem !important;
}

.desktop_image {
  width: 70%;
  height: 40vw;
  margin: auto;
}

.terms_ul > li {
  margin-top: 0.875rem;
  letter-spacing: 1px;
}

/** Table Styling **/

.restaurant-table {
  overflow-x: auto;
  /* background: #cee9ef !important; */
  /* .ant-table-wrapper{
    background: #cee9ef;
  } */
  .ant-table {
    background: #cee9ef !important;
    width: auto;
    /* margin-left: 3%; */
  }

  .ant-table-thead {
    background: #ababab;
    color: "red";
  }
}

@media screen and (max-width: 880px) {
  .restaurant-table {
    overflow-x: auto;
    .ant-table {
      background: #cee9ef !important;
      width: auto;
    }
  }
}
.admin-table {
  .ant-table {
    background: #cee9ef !important;
    width: 90%;
    margin-left: 5%;
  }
}

.ant-table-thead,
.ant-table-cell {
  text-align: center !important;
}

.ant-table-tbody {
  width: auto;
}
.ant-table-row {
  width: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 50% !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/*  Toggle Switches */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
