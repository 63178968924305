@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif !important;
}

@media only screen and (max-width: 768px) {
  .appbarDesktop,
  .getDiscountSearchDesktop,
  .viewAllLinkDesktop,
  .recommendedBlueDesktop {
    display: none !important;
  }
  .getDiscountLocation {
    width: 20px !important;
    height: 20px !important;
  }
  .recommendedBlue .recommendedBlueDiscount {
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .recommendedBlue .recommendedBlueDiscount + div {
    display: none;
  }
  .recommendedBlue .recommendedBlueCenterBox {
    margin: 0;
    align-items: flex-start;
  }
  .removeContainerPadding {
    padding: 0 !important;
  }
  .listViewSelect {
    height: 36px;
    min-width: 100px;
  }
  .listViewDropdowns {
    margin-top: 5px;
  }
  .showMapFloating,
  .showListFloating {
    width: 131px !important;
    height: 40px !important;
    gap: 8px !important;
    /* bottom: 50% !important; */
    transform: translate(-50%, -50%);
  }
  .showMapFloating p,
  .showListFloating p {
    font-size: 14px;
  }
  .showListFloating {
    bottom: 90% !important;
  }
  .viewDetailsContainer {
    margin-top: 20px;
    padding-bottom: 40px;
  }
  .viewDetailsContainer .css-1b01wbp-MuiGrid-root {
    margin-bottom: 20px;
  }
  .viewDetailsWhiteBox {
    padding-top: 20px !important;
  }
  .detailsBrandName {
    font-size: 20px !important;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detailsBranchName {
    max-width: 225px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detailsOfferCopy {
    font-size: 18px !important;
  }
  .bottomMenuBox .active svg,
  .bottomMenuBox .active p {
    color: #006fcf !important;
  }
  .bottomMenuBox .active .homeIconStroke svg path {
    stroke: #006fcf;
  }
  .floatingbookNow {
    width: 100% !important;
    /* bottom: 15% !important; */
    /* padding: 0 16px; */
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    margin: 20px 0 0 !important;
  }
  .viewFooter {
    height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
  }
  .saveMoneyImage {
    width: 100px !important;
    /* height: 100px !important; */
  }

  /* Maps Card Fix */
  .gm-style .gm-style-iw-c {
    padding: 0;
    max-width: inherit !important;
    max-height: inherit !important;
    border-radius: 12px;
  }
  .gm-style .gm-style-iw-d {
    max-height: inherit !important;
    overflow: inherit !important;
  }
  .gm-style .gm-style-iw-tc {
    display: none;
  }

  /* Mobile Map Card */

  .recommendedBlueMobile {
    width : 350px;
    height: 150px;
  }
  .mapCardMobileImg{
    object-fit: cover;
    width: 125px;
    height: 100%;
  }
  .closeBtn:hover{
    cursor: pointer !important;
  }
  .closeImg:hover{
transform: scaleX(2);
transform: scaleY(2);
  }
}

@media only screen and (min-width: 769px) {
  .appbarMobile,
  .getDiscountSearchMobile,
  .viewAllBtnMobile,
  .recommendedBlueMobile,
  .bottomMenuBox {
    display: none !important;
  }
  .viewAllLinkDesktop {
    font-weight: 600;
    font-size: 18px;
    color: #006fcf;
    text-decoration: none;
  }
  .listViewDropdowns {
    margin-top: -27px;
  }
  .listViewSelect {
    height: 40px;
    min-width: 100px;
  }

  /* Maps Card Fix */
  .gm-style .gm-style-iw-c {
    padding: 0;
    border-radius: 12px;
  }
  .gm-style .gm-style-iw-d {
    padding: 0;
    overflow: inherit !important;
    max-height: inherit !important;
  }
  .gm-style .gm-style-iw-c {
    max-height: inherit !important;
  }
  .gm-style .gm-style-iw-tc::after {
    display: none;
  }
}

.listViewSelect .MuiSelect-select {
  margin-top: -5px;
}
.listViewSelect .MuiSvgIcon-root {
  top: calc(50% - 3px);
  transform: translateY(-50%);
}

.hoverUnderline:hover {
  text-decoration: underline;
}
.saveMoneyImage {
  width: 170px;
}
